const _ = require('lodash');

export function getPaymentExportFields() {
    return [{
        title: null,
        fields: [
            {'id': 'invoiceNumberCSV', 'value': 'invoiceNumber', 'label': 'INV #'},
            {'id': 'categoryCSV', 'value': 'category', 'label': 'Category'},
            {'id': 'rinkNameCSV', 'value': 'rinkName', 'label': 'Venue'},
            {'id': 'groupNameCSV', 'value': 'groupName', 'label': 'Group Name'},
            {'id': 'nameCSV', 'value': 'name', 'label': 'Customer Name'},
            {'id': 'phoneCSV', 'value': 'phone', 'label': 'Customer Phone'},
            {'id': 'emailIdCSV', 'value': 'emailId', 'label': 'Customer Email'},
            {'id': 'addressCSV', 'value': 'address', 'label': 'Customer Address'},
            {'id': 'eventTypeCSV', 'value': 'eventType', 'label': 'Event Type'},
            {'id': 'eventNameCSV', 'value': 'eventName', 'label': 'Event Name'},
            {'id': 'dayCSV', 'value': 'day', 'label': 'Date & Time'},
            {'id': 'seperateDateCSV', 'value': 'seperateDate', 'label': 'Date'},
            {'id': 'seperateTimeCSV', 'value': 'seperateTime', 'label': 'Time'},
            {'id': 'startTimeCSV', 'value': 'startTime', 'label': 'Start Time'},
            {'id': 'hoursCSV', 'value': 'hours', 'label': 'Hours'},
            {'id': 'subtotalCSV', 'value': 'subTotal', 'label': 'SubTotal'},
            {'id': 'totalCSV', 'value': 'total', 'label': 'Total'},
            {'id': 'taxCSV', 'value': 'tax', 'label': 'Tax'},
            {'id': 'taxAmountCSV', 'value': 'taxAmount', 'label': 'Tax $'},
            {'id': 'isPaidCSV', 'value': 'isPaid', 'label': 'Paid'},
            {'id': 'isRepeatCSV', 'value': 'isRepeat', 'label': 'Repeat'},
            {'id': 'statusCSV', 'value': 'status', 'label': 'Status'},
            {'id': 'referenceCSV', 'value': 'referenceId', 'label': 'Reference'},
            {'id': 'agreementNumberCSV', 'value': 'agreementNumber', 'label': 'AGR'},
            {'id': 'paymentMethodCSV', 'value': 'paymentMethod', 'label': 'Method'},
            {'id': 'noteCSV', 'value': 'note', 'label': 'Notes'},
            {'id': 'descriptionCSV', 'value': 'description', 'label': 'Description'},
            {'id': 'dateBookedCSV', 'value': 'dateBooked', 'label': 'Order Date'},
            {'id': 'orderIdCSV', 'value': 'orderId', 'label': 'Order Id'},
            {'id': 'paymentDateCSV', 'value': 'paymentDate', 'label': 'Payment Date'},
            {'id': 'paymentTimeCSV', 'value': 'paymentTime', 'label': 'Payment Time'},
            {'id': 'cpcCSV', 'value': 'cpc', 'label': 'CPC'},
            {'id': 'accountCSV', 'value': 'account', 'label': 'Account'},
        ]
    }];
}

export function getPaymentExportNewFields(settings = {}) {
    let fields = [
        {
            title: null,
            fields: [
                {'id': 'groupNameCSV', 'value': 'groupName', 'label': 'Group Name'},
                {'id': 'nameCSV', 'value': 'name', 'label': 'Customer Name'},
                {'id': 'emailIdCSV', 'value': 'emailId', 'label': 'Customer Email'},
                {'id': 'phoneCSV', 'value': 'phone', 'label': 'Customer Phone'},
                {'id': 'addressCSV', 'value': 'address', 'label': 'Customer Address'},
                {'id': 'paymentMethodCSV', 'value': 'paymentMethod', 'label': 'Payment Method'},
                {'id': 'ccFeesCSV', 'value': 'ccFees', 'label': settings.creditCardFeeLabel || 'Credit Card Fees'},
                {'id': 'noteCSV', 'value': 'note', 'label': 'Notes'},
                {'id': 'paymentDateCSV', 'value': 'paymentDate', 'label': 'Payment Date'},
                {'id': 'paymentTimeCSV', 'value': 'paymentTime', 'label': 'Payment Time'},
            ]
        },
        {
            title: "Booking Details",
            fields: [
                {'id': 'bookingIsPaidCSV', 'value': 'bookingIsPaid', 'label': 'Booking Paid'},
                {'id': 'bookingRinkNameCSV', 'value': 'bookingRinkName', 'label': 'Booking Venue'},
                {'id': 'bookingCategoryCSV', 'value': 'bookingCategory', 'label': 'Booking Category'},
                {'id': 'bookingEventTypeCSV', 'value': 'bookingEventType', 'label': 'Booking Event Type'},
                {'id': 'bookingEventNameCSV', 'value': 'bookingEventName', 'label': 'Booking Event Name'},
                {'id': 'bookingDayCSV', 'value': 'bookingDay', 'label': 'Booking Day'},
                {'id': 'bookingSeperateDateCSV', 'value': 'bookingSeperateDate', 'label': 'Booking Date'},
                {'id': 'bookingSeperateTimeCSV', 'value': 'bookingSeperateTime', 'label': 'Booking Time'},
                {'id': 'bookingStartTimeCSV', 'value': 'bookingStartTime', 'label': 'Booking Date & Time'},
                {'id': 'bookingHoursCSV', 'value': 'bookingHours', 'label': 'Booking Hours'},
                {'id': 'bookingSubTotalCSV', 'value': 'bookingSubTotal', 'label': 'Booking Subtotal'},
                {'id': 'bookingTotalCSV', 'value': 'bookingTotal', 'label': 'Booking Total'},
                {'id': 'bookingTaxCSV', 'value': 'bookingTax', 'label': 'Booking Tax %'},
                {'id': 'bookingTaxAmountCSV', 'value': 'bookingTaxAmount', 'label': 'Booking Tax $'},
                {'id': 'bookingIsRepeatCSV', 'value': 'bookingIsRepeat', 'label': 'Booking Repeat'},
                {'id': 'bookingStatusCSV', 'value': 'bookingStatus', 'label': 'Booking Status'},
                {'id': 'bookingReferenceIdCSV', 'value': 'bookingReferenceId', 'label': 'Booking Ref. #'},
                {'id': 'bookingDescriptionCSV', 'value': 'bookingDescription', 'label': 'Booking Description'},
                {'id': 'dateBookedCSV', 'value': 'dateBooked', 'label': 'Booking Order Date'},
                {'id': 'bookingOrderIdCSV', 'value': 'bookingOrderId', 'label': 'Booking Order Id'},
                {'id': 'bookingCPCCSV', 'value': 'bookingCPC', 'label': 'Booking CPC'},
                {'id': 'bookingAccountCSV', 'value': 'bookingAccount', 'label': 'Booking Account'},
            ]
        },
        {
            title: "Invoice Details",
            fields: [
                {'id': 'invoiceIsPaidCSV', 'value': 'invoiceIsPaid', 'label': 'INV Paid'},
                {'id': 'invoiceNumberCSV', 'value': 'invoiceNumber', 'label': 'INV #'},
                {'id': 'invoiceLabelCSV', 'value': 'invoiceLabel', 'label': 'INV Label'},
                {'id': 'invoiceTotalCSV', 'value': 'invoiceTotal', 'label': 'INV Total'},
                {'id': 'invoiceTaxAmountCSV', 'value': 'invoiceTaxAmount', 'label': 'INV Tax $'},
                {'id': 'invoiceSubTotalCSV', 'value': 'invoiceSubTotal', 'label': 'INV Subtotal'},
                {'id': 'invoiceDateCSV', 'value': 'invoiceDate', 'label': 'INV Date'},
                {'id': 'invoiceDueDateCSV', 'value': 'invoiceDueDate', 'label': 'INV Due Date'},
                {'id': 'invoiceAmountPaidCSV', 'value': 'invoiceAmountPaid', 'label': 'INV Amount Paid'},
                {'id': 'invoiceAmountDueCSV', 'value': 'invoiceAmountDue', 'label': 'INV Amount Due'},
            ]
        },
        {
            title: "Agreement Details",
            fields: [
                {'id': 'agreementIsPaidCSV', 'value': 'agreementIsPaid', 'label': 'AGR Paid'},
                {'id': 'agreementNumberCSV', 'value': 'agreementNumber', 'label': 'AGR #'},
                {'id': 'agreementDateCSV', 'value': 'agreementDate', 'label': 'AGR Date'},
                {'id': 'agreementDueDateCSV', 'value': 'agreementDueDate', 'label': 'AGR Due Date'},
                {'id': 'agreementTotalCSV', 'value': 'agreementTotal', 'label': 'AGR Total'},
                {'id': 'agreementTaxAmountCSV', 'value': 'agreementTaxAmount', 'label': 'AGR Tax $'},
                {'id': 'agreementSubTotalCSV', 'value': 'agreementSubTotal', 'label': 'AGR Subtotal'},
                {'id': 'agreementAmountPaidCSV', 'value': 'agreementAmountPaid', 'label': 'AGR Amount Paid'},
                {'id': 'agreementAmountDueCSV', 'value': 'agreementAmountDue', 'label': 'AGR Amount Due'},
            ]
        }
    ];

    return fields
}

export function getBookingExportFields(settings = {}) {
    return [{
        title: null,
        fields: [
            {'id': 'groupNameCSV', 'value': 'groupName', 'label': 'Group Name'},
            {'id': 'nameCSV', 'value': 'name', 'label': 'Customer Name'},
            {'id': 'emailIdCSV', 'value': 'emailId', 'label': 'Customer Email'},
            {'id': 'phoneCSV', 'value': 'phone', 'label': 'Customer Phone'},
            {'id': 'addressCSV', 'value': 'address', 'label': 'Customer Address'},
            {'id': 'customerTypeCSV', 'value': 'customerType', 'label': 'Customer Type'},
            {'id': 'categoryCSV', 'value': 'category', 'label': 'Category'},
            {'id': 'rinkNameCSV', 'value': 'rinkName', 'label': 'Venue'},
            {'id': 'eventTypeCSV', 'value': 'eventType', 'label': 'Event Type'},
            {'id': 'eventNameCSV', 'value': 'eventName', 'label': 'Event Name'},
            {'id': 'dayCSV', 'value': 'day', 'label': 'Day'},
            {'id': 'separateDateCSV', 'value': 'separateDate', 'label': 'Date'},
            {'id': 'separateTimeCSV', 'value': 'separateTime', 'label': 'Time'},
            {'id': 'startTimeRawCSV', 'value': 'startTimeRaw', 'label': 'Date & Time'},
            {'id': 'startTimeCSV', 'value': 'startTime', 'label': 'Date & Time (String)'},
            {'id': 'hoursCSV', 'value': 'hours', 'label': 'Hours'},
            {'id': 'rateCSV', 'value': 'rate', 'label': 'Rate'},
            {'id': 'subTotalCSV', 'value': 'subTotal', 'label': 'Subtotal'},
            {'id': 'taxCSV', 'value': 'tax', 'label': 'Tax %'},
            {'id': 'taxAmountCSV', 'value': 'taxAmount', 'label': 'Tax Amount'},
            {'id': 'totalCSV', 'value': 'total', 'label': 'Total Amount'},
            {'id': 'isPaidCSV', 'value': 'isPaid', 'label': 'Paid'},
            {'id': 'paidCCFeesCSV', 'value': 'paidCCFees', 'label': `Paid ${settings.creditCardFeeLabel || 'Credit Card Fees'}`},
            {'id': 'isRepeatCSV', 'value': 'isRepeat', 'label': 'Repeats'},
            {'id': 'statusCSV', 'value': 'status', 'label': 'Status'},
            {'id': 'referenceIdCSV', 'value': 'referenceId', 'label': 'Booking #'},
            {'id': 'invoiceNumberCSV', 'value': 'invoiceNumber', 'label': 'Invoice #'},
            {'id': 'agreementNumberCSV', 'value': 'agreementNumber', 'label': 'Agreement #'},
            {'id': 'paymentMethodCSV', 'value': 'paymentMethod', 'label': 'Payment Method'},
            {'id': 'noteCSV', 'value': 'note', 'label': 'Notes'},
            {'id': 'descriptionCSV', 'value': 'description', 'label': 'Description'},
            {'id': 'dateBookedCSV', 'value': 'dateBooked', 'label': 'Order Date'},
            {'id': 'orderIdCSV', 'value': 'orderId', 'label': 'Order ID'},
            {'id': 'paymentDateCSV', 'value': 'paymentDate', 'label': 'Payment Date'},
            {'id': 'paymentTimeCSV', 'value': 'paymentTime', 'label': 'Payment Time'},
            {'id': 'cpcCSV', 'value': 'cpc', 'label': 'CPC'},
        ]
    }];
}

export function getBookingNonFinancialExportFields() {
    return [{
        title: null,
        fields: [
            {'id': 'groupNameCSV', 'value': 'groupName', 'label': 'Group Name'},
            {'id': 'nameCSV', 'value': 'name', 'label': 'Customer Name'},
            {'id': 'emailIdCSV', 'value': 'emailId', 'label': 'Customer Email'},
            {'id': 'phoneCSV', 'value': 'phone', 'label': 'Customer Phone'},
            {'id': 'addressCSV', 'value': 'address', 'label': 'Customer Address'},
            {'id': 'customerTypeCSV', 'value': 'customerType', 'label': 'Customer Type'},
            {'id': 'categoryCSV', 'value': 'category', 'label': 'Category'},
            {'id': 'rinkNameCSV', 'value': 'rinkName', 'label': 'Venue'},
            {'id': 'eventTypeCSV', 'value': 'eventType', 'label': 'Event Type'},
            {'id': 'eventNameCSV', 'value': 'eventName', 'label': 'Event Name'},
            {'id': 'dayCSV', 'value': 'day', 'label': 'Day'},
            {'id': 'separateDateCSV', 'value': 'separateDate', 'label': 'Date'},
            {'id': 'separateTimeCSV', 'value': 'separateTime', 'label': 'Time'},
            {'id': 'startTimeRawCSV', 'value': 'startTimeRaw', 'label': 'Date & Time'},
            {'id': 'startTimeCSV', 'value': 'startTime', 'label': 'Date & Time (String)'},
            {'id': 'hoursCSV', 'value': 'hours', 'label': 'Hours'},
            {'id': 'isRepeatCSV', 'value': 'isRepeat', 'label': 'Repeats'},
            {'id': 'statusCSV', 'value': 'status', 'label': 'Status'},
            {'id': 'referenceIdCSV', 'value': 'referenceId', 'label': 'Booking #'},
            {'id': 'noteCSV', 'value': 'note', 'label': 'Notes'},
            {'id': 'descriptionCSV', 'value': 'description', 'label': 'Description'},
            {'id': 'dateBookedCSV', 'value': 'dateBooked', 'label': 'Order Date'},
            {'id': 'orderIdCSV', 'value': 'orderId', 'label': 'Order ID'},
        ]
    }];
}

export function getInvoiceExportFields(settings) {
    return [{
        title: null,
        fields: [
            {'id': 'invoiceNumberCSV', 'value': 'invoiceNumber', 'label': 'Invoice #'},
            {'id': 'invoiceLabelCSV', 'value': 'invoiceLabel', 'label': 'Invoice Label'},
            {'id': 'rinkNamesCSV', 'value': 'rinkNames', 'label': 'Venue'},
            {'id': 'categoriesCSV', 'value': 'categories', 'label': 'Category'},
            {'id': 'groupNameCSV', 'value': 'groupName', 'label': 'Group Name'},
            {'id': 'nameCSV', 'value': 'name', 'label': 'Customer Name'},
            {'id': 'phoneCSV', 'value': 'phone', 'label': 'Customer Phone'},
            {'id': 'emailIdCSV', 'value': 'emailId', 'label': 'Customer Email'},
            {'id': 'addressCSV', 'value': 'address', 'label': 'Customer Address'},
            {'id': 'customerTypeCSV', 'value': 'customerType', 'label': 'Customer Type'},
            {'id': 'invoiceDateCSV', 'value': 'invoiceDate', 'label': 'Invoice Date'},
            {'id': 'dueDateCSV', 'value': 'dueDate', 'label': 'Due Date'},
            {'id': 'subTotalCSV', 'value': 'subTotal', 'label': 'SubTotal'},
            {'id': 'taxAmountCSV', 'value': 'taxAmount', 'label': 'Tax $'},
            {'id': 'totalCSV', 'value': 'total', 'label': 'Total'},
            {'id': 'amountPaidCSV', 'value': 'amountPaid', 'label': 'Amount Paid'},
            {'id': 'ccFeesPaidCSV', 'value': 'ccFeesPaid', 'label': `Paid ${settings.creditCardFeeLabel || 'Credit Card Fees'}` },
            {'id': 'amountDueCSV', 'value': 'amountDue', 'label': 'Amount Due'},
            {'id': 'partialPaymentsCSV', 'value': 'partialPayments', 'label': 'Partial Payments'},
            {'id': 'paymentTypesCSV', 'value': 'paymentTypes', 'label': 'Payment Type'},
            {'id': 'paymentDatesCSV', 'value': 'paymentDates', 'label': 'Payment Date'},
            {'id': 'rinkTotalsCSV', 'value': 'rinkTotals', 'label': 'Venue Totals'},
            {'id': 'eventTypesCSV', 'value': 'eventTypes', 'label': 'Event Types'},
        ]
    }];
}

export function getInvoiceLineItemExportFields(settings) {
    return [{
        title: "Invoices",
        fields: [
            {'id': 'invoiceNumberCSV', 'value': 'invoiceNumber', 'label': 'Invoice #'},
            {'id': 'invoiceLabelCSV', 'value': 'invoiceLabel', 'label': 'Invoice Label'},
            {'id': 'rinkNamesCSV', 'value': 'rinkNames', 'label': 'Venue'},
            {'id': 'categoriesCSV', 'value': 'categories', 'label': 'Category'},
            {'id': 'groupNameCSV', 'value': 'groupName', 'label': 'Group Name'},
            {'id': 'nameCSV', 'value': 'name', 'label': 'Customer Name'},
            {'id': 'phoneCSV', 'value': 'phone', 'label': 'Customer Phone'},
            {'id': 'emailIdCSV', 'value': 'emailId', 'label': 'Customer Email'},
            {'id': 'addressCSV', 'value': 'address', 'label': 'Customer Address'},
            {'id': 'customerTypeCSV', 'value': 'customerType', 'label': 'Customer Type'},
            {'id': 'invoiceDateCSV', 'value': 'invoiceDate', 'label': 'Invoice Date'},
            {'id': 'dueDateCSV', 'value': 'dueDate', 'label': 'Due Date'},
            {'id': 'subTotalCSV', 'value': 'subTotal', 'label': 'SubTotal'},
            {'id': 'taxAmountCSV', 'value': 'taxAmount', 'label': 'Tax $'},
            {'id': 'totalCSV', 'value': 'total', 'label': 'Total'},
            {'id': 'amountPaidCSV', 'value': 'amountPaid', 'label': 'Amount Paid'},
            {'id': 'ccFeesPaidCSV', 'value': 'ccFeesPaid', 'label': `Paid ${settings.creditCardFeeLabel || 'Credit Card Fees'}` },
            {'id': 'amountDueCSV', 'value': 'amountDue', 'label': 'Amount Due'},
            {'id': 'partialPaymentsCSV', 'value': 'partialPayments', 'label': 'Partial Payments'},
            {'id': 'paymentTypesCSV', 'value': 'paymentTypes', 'label': 'Payment Type'},
            {'id': 'paymentDatesCSV', 'value': 'paymentDates', 'label': 'Payment Date'},
            {'id': 'rinkTotalsCSV', 'value': 'rinkTotals', 'label': 'Venue Totals'},
            {'id': 'eventTypesCSV', 'value': 'eventTypes', 'label': 'Event Types'},
        ]
    },{
        title: "Line Items",
        fields: [
            {'id': 'itemNameCSV', 'value': 'itemName', 'label': 'Item Name'},
            {'id': 'venueNameCSV', 'value': 'venueName', 'label': 'Venue Name'},
            {'id': 'descriptionCSV', 'value': 'description', 'label': 'Description'},
            {'id': 'eventNameCSV', 'value': 'eventName', 'label': 'Event Name'},
            {'id': 'hoursCSV', 'value': 'hours', 'label': 'Hours'},
            {'id': 'rateCSV', 'value': 'rate', 'label': 'Rate'},
            {'id': 'taxCSV', 'value': 'tax', 'label': 'Tax'},
            {'id': 'amountCSV', 'value': 'amount', 'label': 'Amount'},
        ]
    }];
}

export function getInvoicePaymentExportFields(creditCardOption = false, settings= {}) {
    let fields = [{
        title: null,
        fields: [
            {'id': 'invoiceNumberCSV', 'value': 'invoiceNumber', 'label': 'INV #'},
            {'id': 'invoiceLabelCSV', 'value': 'invoiceLabel', 'label': 'Invoice Label'},
            {'id': 'categoryIdCSV', 'value': 'categoryId', 'label': 'Category'},
            {'id': 'rinkNameCSV', 'value': 'rinkName', 'label': 'Venue'},
            {'id': 'groupNameCSV', 'value': 'groupName', 'label': 'Group Name'},
            {'id': 'nameCSV', 'value': 'name', 'label': 'Customer Name'},
            {'id': 'phoneCSV', 'value': 'phone', 'label': 'Customer Phone'},
            {'id': 'emailIdCSV', 'value': 'emailId', 'label': 'Customer Email'},
            {'id': 'addressCSV', 'value': 'cAddress', 'label': 'Customer Address'},
            {'id': 'invoiceDateCSV', 'value': 'invoiceDate', 'label': 'Creation Date'},
            {'id': 'dueDateCSV', 'value': 'dueDate', 'label': 'Due Date'},
            {'id': 'subTotalCSV', 'value': 'subTotal', 'label': 'SubTotal'},
            {'id': 'taxAmountCSV', 'value': 'taxAmount', 'label': 'Tax Amount'},
            {'id': 'totalCSV', 'value': 'total', 'label': 'Total Amount'},
            {'id': 'amountPaidCSV', 'value': 'amountPaid', 'label': 'Amount'},
            {'id': 'amountDueCSV', 'value': 'amountDue', 'label': 'Amount Due'},
            {'id': 'partialPaymentsCSV', 'value': 'partialPayments', 'label': 'Partial Payments'},
            {'id': 'paymentTypeCSV', 'value': 'paymentType', 'label': 'Payment Type'},
            {'id': 'referenceCSV', 'value': 'reference', 'label': 'Reference'},
            {'id': 'paymentDateCSV', 'value': 'paymentDate', 'label': 'Payment Date'},
        ]
    }];
    if (creditCardOption) {
        fields[0].fields.push(
            {'id': 'ccFeesCSV', 'value': 'ccFees', 'label': settings.creditCardFeeLabel || 'Credit Card Fees'},
        );
    }
    return fields;
}

export function getCustomerExportFields() {
    return [{
        title: null,
        fields: [
            {'id': 'groupNameCSV', 'value': 'groupName', 'label': 'Group Name'},
            {'id': 'firstNameCSV', 'value': 'firstName', 'label': 'First Name'},
            {'id': 'lastNameCSV', 'value': 'lastName', 'label': 'Last Name'},
            {'id': 'emailIdCSV', 'value': 'emailId', 'label': 'Email'},
            {'id': 'phoneCSV', 'value': 'phone', 'label': 'Phone'},
            {'id': 'phone2CSV', 'value': 'phone2', 'label': 'Phone 2'},
            {'id': 'addressCSV', 'value': 'address', 'label': 'Address'},
            {'id': 'customerTypeCSV', 'value': 'customerType', 'label': 'Customer Type'},
            {'id': 'statusCSV', 'value': 'status', 'label': 'Status'},
            {'id': 'noOfBookingsCSV', 'value': 'noOfBookings', 'label': 'Bookings'},
            {'id': 'hoursCSV', 'value': 'hours', 'label': 'Hours'},
            {'id': 'totalCSV', 'value': 'total', 'label': 'Total Value'},
            {'id': 'paidCSV', 'value': 'paid', 'label': 'Paid'},
            {'id': 'rateCSV', 'value': 'rate', 'label': 'Rate'},
            {'id': 'outStandingCSV', 'value': 'outStanding', 'label': 'Outstanding'},
            {'id': 'creditCSV', 'value': 'credit', 'label': 'Credit'},
        ]
    }];
}

export function getCreditExportFields() {
    return [{
        title: null,
        fields: [
            {'id': 'groupNameCSV', 'value': 'groupName', 'label': 'GroupName'},
            {'id': 'nameCSV', 'value': 'name', 'label': 'Customer Name'},
            {'id': 'phoneCSV', 'value': 'phone', 'label': 'Customer Phone'},
            {'id': 'emailIdCSV', 'value': 'emailId', 'label': 'Customer Email'},
            {'id': 'creditSourceIdCSV', 'value': 'creditSourceId', 'label': 'Source'},
            {'id': 'createdAtCSV', 'value': 'createdAt', 'label': 'Date'},
            {'id': 'creditTypeCSV', 'value': 'creditType', 'label': 'Type'},
            {'id': 'amountCSV', 'value': 'amount', 'label': 'Amount'},
        ]
    }];
}

export function getClassParticipantsExportFields(participantFields) {
    return [{
        title: "Class Details",
        fields: [
            {'id': 'eventNameCSV', 'value': 'eventName', 'label': 'Event Name'},
            {'id': 'nameCSV', 'value': 'name', 'label': 'Venue'},
            {'id': 'startTimeCSV', 'value': 'startTime', 'label': 'Date and Time'},
            {'id': 'bookingIdCSV', 'value': 'bookingId', 'label': 'Class ID'},
        ]
    }, {
        title: "Payment Details",
        fields: [
            {'id': 'createdAtCSV', 'value': 'createdAt', 'label': 'Date Registered'},
            {'id': 'costCSV', 'value': 'cost', 'label': 'Class Price'},
            {'id': 'stripeTokenCSV', 'value': 'stripeToken', 'label': 'Paid'},
        ]
    }, {
        title: "Participant Details",
        fields: _.map(participantFields, (f) => {
            return {
                id: f.id + "CSV",
                value: f.id + "",
                label: f.name,
            }
        })
    }];
}
